.course-div {
  /* position: absolute; */
  width: 382px;
  height: 410px;
  left: 280px;
  top: 218px;

  /* background: #ffffff; */
  border-radius: 10px;
}

.course-search {
  /* position: absolute; */
  width: 320px;
  height: 40px;
  left: 280px;
  top: 151px;
}

.course-group {
  position: absolute;
  width: 281px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #252f6a;
}

.course-info {
  box-sizing: border-box;
  /* position: absolute; */
  width: 323px;
  height: 63px;
  left: 306px;
  top: 515px;
  border-bottom: 1px solid #e9ecef;
}

.course-enter {
  /* position: absolute;     */
  width: 125px;
  height: 40px;
  left: 410px;
  top: 566px;
}

.accordion-item {
  margin-bottom: 20px;
  overflow: hidden;
}

.accordion-button {
  box-shadow: none;
}

.accordion-button span {
  font-size: 18px;
  color: #686868;
  font-family: "Poppins";
  font-weight: 600;
}

.accordion-item:first-of-type {
  border-radius: 10px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
}

.accordion-button:not(.collapsed) {
  background-color: #fff;
}

.acc_header_btn {
  right: 45px;
}

.global_btn {
  background: #252f6a;
  padding: 9px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}

.global_btn_danger {
  background: #f46a6a;
  padding: 9px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}

.add_module input[type="file"]::file-selector-button {
  border: 1px solid #252f6a;
  padding: 5px;
  border-radius: 3px;
  background-color: #fff;
  color: #252f6a;
  font-size: 13px;
}

.required_star {
  color: #ee4b63 !important;
}

.custom_form_control {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
  color: #323133;
  padding: 10px 20px;
  border: 2px solid #ced4da;
  border-radius: 5px;
}

.control-label {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
  color: #686868;
}

.badge {
  border-radius: 3px !important;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  min-width: 75px;
  /* width: 100%; */
  max-width: 100px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge_active {
  background: #1ab69d !important;
  color: #f2f2f2;
}

.badge_deactive {
  background: #f89752 !important;
  color: #f2f2f2;
}
.badge_unverified {
  background: #f2f2f2 !important;
  color: #6a7187;
}
.profile_name {
  font-size: 20px;
  color: #323133;
  font-weight: 600;
  font-family: "Poppins";
}

.profile_email {
  font-size: 14px;
  color: #323133;
  font-weight: 500;
  font-family: "Poppins";
}

.info_badge {
  background-color: #ccf0e3;
  border-radius: 20px;
  /* max-width: 185px; */
  text-align: center;
  padding: 3px 20px;
}

.info_badge span {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  color: #34c38f;
}

.custom_card {
  background-color: #fff;
  border-radius: 5px;
  filter: drop-shadow(0px 10px 20px rgba(18, 38, 63, 0.031));
  margin-bottom: 24px;
  padding-bottom: 30px;
}

.cover_pic {
  height: 107px;
  background-color: #e7f3f8;
}

.profile_pic {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #000;
  position: absolute;
  top: -75px;
  left: 30px;
}

.profile_pic > img {
  object-fit: cover;
  height: 100%;
}

.profile_info {
  margin-top: 80px;
  padding-left: 40px;
}

.sun_heading {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  color: #252f6a;
}

.sub_heading {
  font-size: 20px;
  font-weight: 500;
  font-family: "Poppins";
  color: #252f6a;
}

.lbl_empty_content {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  color: #495057;
}

.btn_outline {
  background: #e0e5fa;
  border-radius: 7px;
  border: 1px solid #252f6a;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #252f6a;
}

.btn_outline_bgwhite {
  background: #fff;
  border-radius: 7px;
  border: 2px solid #252f6a;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #252f6a;
}

.para {
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
  color: #323133;
}

.sm_title {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #686868;
}

.sm_content {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
  color: #323133;
}

/* DETAILS PAGE STYLING END */

/* CUSTOM SWITCH TOGGLE STYLE BEGIN */

.form-switch .form-check-input {
  height: 16px;
  width: 26px;
  background-color: #d1d5db;
  border-color: #d1d5db;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.form-switch .form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
  outline: 0;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
}

.form-switch .form-check-input:checked {
  background-color: #32bda7;
  border-color: #32bda7;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

.form-switch .form-check-input2:checked {
  background-color: #50a5f1;
  border-color: #50a5f1;
  border: none;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255,255,255,1.0)'/></svg>");
}

/* CUSTOM SWITCH TOGGLE STYLE END */

.action_container .mdi {
  cursor: pointer;
}

.action_container .mdi-eye-outline {
  color: #f89752;
}

.btn_pagination {
  background-color: #fff !important;
  padding: 9px 13px;
  border: 1px solid #ced4da;
  border-radius: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: "Poppins";
  color: #46474f !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.pagination_curr_page {
  padding: 8px 13px;
  border: 1px solid #252f6a;
  background-color: #252f6a;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins";
  color: #fff;
}

.custom_searchbox {
  border-radius: 5px !important;
  border: 1px solid #ced4da;
}

.question_number {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
  color: #252f6a;
}

.lbl_question_container {
  border-bottom: 1px solid #ced4da;
}

.lbl_question_options {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  color: #74788d;
}

.question_option_input {
  border: 1px solid #a6b0cf;
  background-color: #fff !important;
  border-radius: 6px;
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
  height: 44px;
}

.question_option_radio {
  border: 2px solid #a6b0cf;
  height: 25px;
  width: 25px;
}

.question_text {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #3d3d3d;
  text-transform: capitalize;
}

.question_answer {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #495057;
  text-transform: capitalize;
}

.skill_tag {
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins";
  color: #3d3d3d;
  text-transform: capitalize;
  background-color: #f2f2f2;
  padding: 6px 20px;
  border-radius: 25px;
  margin: 0 20px 20px 0;
  white-space: nowrap;
  display: inline-block;
}
.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Adjust the minmax value as needed */
  gap: 10px; /* Adjust the gap between skills */
}
.candidate_skill {
  font-size: 11px;
  font-weight: 500;
  font-family: "Poppins";
  color: #3d3d3d;
  text-transform: capitalize;
  background-color: #f2f2f2;
  padding: 5px 10px;
  border-radius: 25px;
  white-space: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 25px;
  margin-right: 5px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to display */
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 25px;
  min-height: 30px;
}

.post_name {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins";
  color: #252f6a;
}

.employer_name {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #323133;
}

.work_time {
  font-size: 12px;
  font-weight: 400;
  font-family: "Poppins";
  color: #686868;
}

.employee_extra_info {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #323133;
}

.full_devider {
  border: 1px solid #e8dede;
}

.resume_name {
  color: #323133;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
}

.resume_name span {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  color: #686868;
}

.edit_blue_icon {
  color: #556ee6;
}

.delete_red_icon {
  color: #f89752;
}

.btn_outer_upsert {
  border-top: 1px solid #ced4da;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: transparent;
  /* Remove the checkbox background */
  border-color: transparent;
  /* Remove the checkbox border */
  pointer-events: none;
  /* Disable checkbox interaction */
}

.custom-checkbox .custom-control-label::before {
  width: 24px;
  /* Increase checkbox width */
  height: 24px;
  /* Increase checkbox height */
  border: 2px solid #000;
  /* Customize the checkbox border */
}

.jodit-status-bar a.jodit-status-bar-link {
  display: none;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.gallery .gallery-item {
  border: 8px solid rgb(223, 223, 223);
  position: relative;
  border-radius: 8px;
}

.gallery .gallery-item .position-relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.gallery .gallery-item img {
  width: 100%;
  height: 100%; /* Set image height to match container height */
  display: block;
  object-fit: cover;
}

.image-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust background color and opacity */
  color: white;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.gallery .gallery-item:hover .image-details {
  visibility: visible;
  opacity: 1;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.image-name {
  font-size: 14px;
  margin: 0;
}

.gallery-list {
  display: flex;
  flex-direction: column;
}

.gallery-list .gallery-item {
  height: 50px;
  background-color: white;
  border-radius: 5px;
  overflow: hidden;
}

.gallery-list .gallery-item img {
  width: 100px;
  object-fit: cover;
}

.custom-dropdown-control {
  /* min-width: 200px; */
  border: none; /* Remove the border */
  outline: none; /* Remove the outline */
  position: relative;
}

.custom-dropdown-menu {
  box-shadow: 1px 1px 100px 10px #00000006;
  min-width: 200px; /* Make the control width 100% */
  /* /* position: absolute; */
  left: -130px;
  /* top:50px  */
}

.react-dropdown__menu {
  border: none; /* Remove the border */
  box-shadow: none; /* Remove the box shadow */
}

.react-dropdown__indicator {
  display: none; /* Hide the default arrow button */
}

.Dropdown-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: none;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.Dropdown-placeholder {
  display: none;
}

.bg-second{
  background-color: #ee9a60;
}

.bg-prime{
  background-color: #243466;
}

.fc-second{
  color: #ee9a60;
}

.fc-prime{
  color: #243466;
}


/* CSS for custom scrollbar in modal body */
.modal-body {
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc #f5f5f5; /* For Firefox */
}

/* Customize scrollbar for WebKit browsers (e.g., Chrome, Safari) */
.modal-body::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.widget-sm {
  height: 1.5rem;
  width: 1.5rem !important;
}
