
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
//   border: 2px dashed var(--#{$prefix}border-color);
border: 2px dashed #CED4DA;
  background: var(--#{$prefix}custom-white);
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.img_resume{
    width: 147px;
    height: 171px;
    object-fit: cover;
}