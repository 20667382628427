* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Sitka Banner Bold";
  src: url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.eot");
  src: url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/bc6ea445b6ba7c46b4710dc876b522ea.svg#Sitka Banner Bold")format("svg");
}

.home_container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
}

.login_and_signUp {
  align-items: center;
  display: flex;
}

.header {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("./../images/home/banner.png") no-repeat;
  background-position: left center;
  background-size: cover;
}

.header_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 40%;
}

.header_content h1 {
  font-size: 37px;
  font-weight: 700;
  color: #252f6a;
  text-align: center;
  line-height: 38px;
  font-family: "League Spartan", sans-serif;
}


.header_content p {
  font-size: 15px;
  font-weight: 600;
  color: gray;
  text-align: center;
}

.header_content a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-family: "League Spartan", sans-serif;
  background: #252f6a;
  border-radius: 5px;
  padding: 15px 30px;
  text-transform: capitalize;
  text-decoration: none;
  display: inline-block;
}

.img_header_left {
  position: absolute;
  left: 20px;
  top: 8%;
  z-index: 2;
}

.img_header_right {
  position: absolute;
  right: 20px;
  top: 8%;
  z-index: 2;
}

.online_support,
.enroll_learners {
  background-color: #fff;
  padding: 30px;
  border-top-left-radius: 50px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 50px;
  position: absolute;
  top: 90%;
  right: 70%;
  width: 306px;
  box-shadow: 0 20px 40px 0px rgba(0, 0, 0, 0.3);
}

.enroll_learners {
  margin-top: -50px;
  z-index: 999;
}

.online_support .txt_online_support {
  font-size: 11px;
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
  opacity: 43%;
  font-family: "League Spartan", sans-serif;
}

.online_support .txt_mobile {
  font-size: 20px;
  font-weight: 400;
  color: #181818;
  font-family: "League Spartan", sans-serif;
}

.about_us {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

.lbl_about_us {
  font-size: 14px;
  font-weight: 600;
  color: #808080;
  text-transform: uppercase;
}

.section_heading {
  font-size: 36px;
  font-weight: 700;
  font-family: "League Spartan", sans-serif;
  color: #181818;
  line-height: 45px;
}

.section_desc {
  font-size: 15px;
  font-weight: 500;
  color: #808080;
  line-height: 25px;
}

.bout_us_content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about_us_content .aboutus_list {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
}

.about_banner_container img {
  position: relative;
  z-index: 995;
}

.about_banner_container::before {
  content: "";
  background-image: url("./../images/home/about_shape2.png");
  position: absolute;
  right: 75%;
  bottom: 55%;
  height: 266px;
  width: 269px;
  z-index: 1;
}

.about_banner_container::after {
  content: "";
  background-image: url("./../images/home/about_shape1.png");
  position: absolute;
  left: 65%;
  top: 55%;
  height: 259px;
  width: 273px;
  z-index: 1;
}

.lbl_learners_count {
  color: #f89752;
  font-size: 24px;
  font-weight: 700;
  font-family: "League Spartan", sans-serif;
}

.lbl_learners {
  color: #181818;
  font-size: 14px;
  font-weight: 400;
  font-family: "League Spartan", sans-serif;
}

.small_heading_title {
  font-size: 14px;
  color: #808080;
  font-weight: 600;
  text-transform: uppercase;
}

.online_test_cat {
  padding: 30px;
  border-radius: 5px;
  background: rgb(26 182 157 / 7%);
  text-align: center;
  margin-bottom: 20px;
}

.online_test_cat .cat_title {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
  font-family: "League Spartan", sans-serif;
  line-height: 26px;
  padding: 0 20px;
}

.online_test_cat .cat_desc,
.membership_plan .plan_desc {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  margin: 20px;
}

.online_test_cat .num_of_courses {
  color: #1ab69d;
  font-size: 14px;
  font-weight: 600;
  background: #daf4ef;
  border-radius: 5px;
  padding: 5px 20px;
}

.online_test_cat img {
  max-width: 80px;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}

.non_degree {
  background: rgb(238 74 98 / 7%);
}

.non_degree .num_of_courses {
  color: #ee4a62;
  background: #fce1e5;
}

.off_campus {
  background: rgb(70 100 228 / 7%);
}

.off_campus .num_of_courses {
  color: #4664e4;
  background: #e0e5fa;
}

.hybrid_distance {
  background: rgb(248 148 31 / 7%);
}

.hybrid_distance .num_of_courses {
  color: #f8941f;
  background: #feedda;
}

.plan_container {
  background: #fff;
  border-radius: 10px;
  padding: 40px 40px;
  box-shadow: 0 20px 40px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  height: 500px;
}

.plan_container .plan_amount {
  color: #181818;
  font-size: 40px;
  font-weight: 700;
  font-family: "League Spartan", sans-serif;
}

.plan_name {
  color: #f89752;
  font-size: 14px;
  font-weight: 600;
  font-family: "League Spartan", sans-serif;
  text-transform: uppercase;
}

.lbl_per_month,
.choose_desc,
.testi_desc {
  color: #808080;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}

.list_item {
  font-size: 14px;
  font-weight: 600;
  color: #181818;
}

.plan_benefits {
  border-top: 1px solid #e7e7e7;
  padding-top: 25px;
}

.btn_select_plan {
  font-size: 14px;
  color: #181818;
  font-weight: 600;
  font-family: "League Spartan", sans-serif;
  border: 1px solid #e5e5e5;
  background: #fff;
  padding: 10px 35px;
  border-radius: 5px;
  margin-top: 35px;
}

.our_partners {
  background: #faf6f3;
}

.our_partners .partners_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
}

.our_partners .partners_container img {
  max-width: 120px;
  width: 100%;
  height: auto;
}

.home_input_field {
  outline: none !important;
  border: 0 !important;
  border-bottom: 1px solid #ebebeb !important;
  color: #808080 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.button {
  border-radius: 5px;
  color: #fff;
  background-color: #252f6a !important;
  border-color: #252f6a !important;
  padding: 10px 30px !important;
}

.contact_us .contact_us_banner img {
  max-width: 781px;
  width: 100%;
  height: auto;
}

.our_features {
  background: url("./../images/home/features_bg.png") no-repeat;
  background-position: left top;
  background-size: cover;
  padding: 100px 0;
}

.feature_img {
  border-radius: 10px;
  overflow: hidden;
}

.feature_img img {
  max-width: 370px;
  width: 100%;
}

.feature_content {
  background: #fff;
  padding: 30px 45px;
  border-radius: 10px;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  text-align: left;
}

.feature_content .feature_title {
  color: #181818;
  font-size: 18px;
  font-weight: 400;
  font-family: "League Spartan", sans-serif;
  line-height: 26px;
}

.feature_content .feature_desc {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
}

.features_item {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 200px;
}

.carousel-inner .carousel-item img {
  max-width: 100%;
  height: auto;
}

.choose_title {
  font-size: 20px;
  font-weight: 400;
  font-family: "League Spartan", sans-serif;
  color: #181818;
}

.why_us {
  height: 790px;
  background: url("./../images/home/why_us_bg.png") no-repeat;
  background-position: top center;
  background-size: cover;
}

.why_us .why_us_content {
  max-width: 590px;
  width: 100%;
  padding: 65px;
  border-radius: 5px;
  background: #fff;
  text-align: start;
  box-shadow: 0 10px 40px 0px rgb(26 46 85 / 16%);
}

.why_us_banner {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
}

.why_us_inner {
  position: relative;
}

.why_us_banner img {
  max-width: 850px;
  width: 100% !important;
  height: 100%;
}

.why_us_content {
  position: absolute;
  right: 0;
  top: 120px;
  z-index: 50;
}

/* FOOTER STYLE BEGINS */
.main_footer,
.bottom_bar {
  background: #121212;
}

.social_icons_container {
  display: flex;
  justify-content: space-between;
}

.main_footer .footer_logo img {
  max-width: 293px;
  width: 100%;
  height: auto;
}

.main_footer .footer_text {
  font-size: 14px;
  font-weight: 600;
  color: #bababa;
  line-height: 26px;
}

.main_footer ul li a {
  font-size: 14px;
  font-weight: 600;
  color: #bababa;
  text-decoration: none;
  padding: 2px 0;
  display: block;
}

.foot_section_headting {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  font-family: "League Spartan", sans-serif;
}

.footer_quick_links_menu {
  width: 50%;
}

.bottom_bar {
  border-top: 1px solid #1f2020;
}

.bottom_bar p {
  font-size: 14px;
  font-weight: 600;
  color: #bababa;
}

.bottom_bar a {
  color: #f89752;
  text-decoration: none;
}

/* FOOTER STYLE END */

/* TOP BAR STYLING BEGINS */
.top_bar {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
}

.top_bar_container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}

.top_bar_inner {
  padding: 0 20px;
}

.top_bar_inner .menu_list {
  display: flex;
  align-items: center;
}

.top_bar_inner .menu_inner {
  display: flex;
  align-items: center;
}

.top_bar_inner ul {
  border-right: 2px solid #181818;
}

.top_bar_inner ul li {
  display: inline-block;
}

.hamburger_icon {
  display: none;
}

.top_bar_inner ul li a {
  font-size: 16px;
  color: #181818;
  font-weight: 600;
}

.active_menu {
  color: #f89752 !important;
}

.top_bar_inner ul li a:focus {
  color: #f89752;
}

.home_global_btn {
  /* background: #fff !important;
    border: 1px solid #fff !important;
    color: #181818 !important; */
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: "Poppins";
  display: inline-flex !important;
  align-items: center !important;
}

/* .home_global_btn:focus, .home_global_btn:hover, .home_global_btn:active{
    background: #252F6A !important;
    color: #fff !important;
    box-shadow: none !important;
} */

/* .home_global_btn:hover .bx-user{
    color: #fff !important;
} */

.bx-user {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.hoveredIcon {
  color: #fff !important;
}

.hovered {
  background: #252f6a !important;
  color: #fff !important;
}

.signup_btn {
  background: #252f6a !important;
  color: #fff !important;
}

.btn_login {
  padding: 6px 15px !important;
  margin-left: 5px;
  color: #495057;
}

.btn_login:hover .bx-user {
  color: #fff !important;
}

.i_plus {
  color: #fff !important;
  font-size: 22px !important;
}

.bx-user-plus {
  color: #fff !important;
  font-size: 22px !important;
  /* font-weight: 500 !important; */
}

/* TOP BAR STYLING END */

/* our_features_carousel CSS BEGINS */
.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}

.swiper-wrapper {
  padding-bottom: 30px !important;
}

.our_partners .swiper-wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 0px !important;
  padding-left: 30px;
}
.our_partners .swiper-wrapper img {
  width: 150px;
}
.our_partners .swiper-button-prev {
  left: 0 !important;
  display: none;
}

.our_partners .swiper-button-next {
  right: 0 !important;
  display: none;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #f9f5f2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.features_container {
  padding-left: 30px;
  padding-right: 30px;
}

/* our_features_carousel CSS END */

/* TESTIMONIALS STYLE BEGINS */

.testimonials .testi_container {
  max-width: 338px;
  width: 100%;
  padding: 40px 25px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  box-shadow: 0 4px 15px 0px rgba(0, 0, 0, 0.3);
}

.testimonials .testi_container .testi_shape {
  position: absolute;
  right: 10px;
  top: 8px;
  overflow: hidden;
}

.testimonials .testi_container .testi_shape img {
  max-width: 100px;
  width: 100%;
  height: auto;
}

.testimonials .testi_container .quotes_circle {
  position: absolute;
  bottom: 10px;
  left: 51px;
}

.testimonials .testi_user_img {
  max-width: 70px;
  width: 100%;
  height: auto;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
}

.testimonials .user_name {
  color: #181818;
  font-size: 18px;
  font-weight: 400;
  font-family: "League Spartan", sans-serif;
  line-height: 26px;
}

.testimonials .user_profile {
  color: #808080;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}

.testimonials .slick-slide {
  transform: scale(0.9);
  transition: transform 300ms;
  opacity: 0.5;
}

.testimonials .slick-active {
  transform: scale(1);
  opacity: 1;
}

.slick-dots li {
  margin: 0 !important;
}

/* TESTIMONIALS STYLE END */

/* BOOTSTRAP NAVBAR CSS BEGINS */

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.navbar-nav {
  align-items: center;
}

.top_bar_inner ul li a {
  font-size: 16px;
  color: #181818;
  font-weight: 600;
  padding: 5px 10px;
  display: block;
}

/* BOOTSTRAP NAVBAR CSS END */

@media (max-width: 575.98px) {
  .header {
    height: 100vh;
    position: unset;
    background-position: center;
  }

  .header_content {
    position: unset;
    transform: unset;
    display: block;
    width: 100%;
  }

  .img_header_left {
    display: none;
  }

  .img_header_right {
    display: none;
  }

  .online_support {
    position: unset;
    margin: 0 auto 35px;
  }

  .enroll_learners {
    margin: 20px auto 0;
    position: unset;
  }

  .online_support .txt_online_support {
    font-size: 11px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    opacity: 43%;
    font-family: "League Spartan", sans-serif;
  }

  .online_support .txt_mobile {
    font-size: 20px;
    font-weight: 400;
    color: #181818;
    font-family: "League Spartan", sans-serif;
  }

  .about_us_content {
    display: block !important;
    width: 100%;
  }

  .about_banner_container::after {
    display: none;
  }

  .why_us_content {
    top: 250px;
  }

  .about_banner_container img {
    max-width: 570px;
    width: 100%;
    height: auto;
    margin: 0 15px;
  }

  .online_test .online_test_container {
    padding: 0 15px;
  }

  .our_features {
    background: unset;
    padding: 0 !important;
  }

  .features_container {
    padding-top: 0 !important;
  }

  .why_us {
    position: unset;
    height: unset !important;
  }

  .why_us_inner {
    position: unset !important;
  }

  .why_us_banner {
    position: unset !important;
    margin: 0 0 25px;
  }

  .about_us {
    padding-top: 0;
    padding-bottom: 70px;
    overflow: hidden;
  }

  .about_banner_container::before {
    display: none;
  }

  .plan_container {
    margin: 10px 15px;
  }

  .testimonials_header {
    margin: 0 15px 20px 15px;
    text-align: center !important;
  }

  .our_partners_header {
    margin: 0 15px;
  }

  .partners_container {
    margin: 0 15px;
  }

  .contact_us_banner {
    margin: 0 15px;
    margin-bottom: 25px;
  }

  .contact_us_header {
    margin: 0 15px;
    text-align: center !important;
    margin-top: 20px;
  }

  .contact_us_form {
    margin: 0 15px;
  }

  .main_footer {
    padding: 0 15px;
  }

  .bottom_bar {
    padding: 0 15px;
    text-align: center;
  }

  .online_test_header {
    margin: 0 15px;
  }

  .online_test_cat {
    margin: 0 15px 20px;
  }

  .about_us .about_us_header {
    text-align: center;
    margin-bottom: 25px;
  }

  .enroll_learners {
    width: 275px;
    padding: 18px;
    height: 95px;
  }

  .enroll_learners img {
    height: 50px !important;
    width: 50px !important;
  }

  .online_support {
    width: 275px;
    padding: 18px;
  }

  .social_icons_container {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }

  .navbar-brand img {
    max-width: 170px !important;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .header {
    height: 100vh;
    position: unset;
    background-position: center;
  }

  .header_content {
    position: unset;
    transform: unset;
    display: block;
    width: 100%;
    padding: 0 15px;
  }

  .img_header_left {
    display: none;
  }

  .img_header_right {
    display: none;
  }

  .about_us_content {
    display: block !important;
    width: 100%;
  }

  .about_banner_container img {
    max-width: 570px;
    width: 100%;
    height: auto;
    margin: 0 15px;
  }

  .online_test .online_test_container {
    padding: 0 15px;
  }

  .why_us::before {
    display: none;
  }

  .our_features {
    background: unset;
    padding: 0 !important;
  }

  .features_container {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .why_us {
    position: unset;
    height: unset !important;
  }

  .why_us_inner {
    position: unset !important;
    padding: 0 15px;
  }

  .why_us_banner {
    position: unset !important;
    margin: 0 0 25px;
  }

  .why_us .why_us_content {
    position: unset !important;
    max-width: 100% !important;
    width: 100%;
    padding: 50px 32px;
  }

  .about_us {
    padding-top: 0;
    padding-bottom: 70px;
    overflow: hidden;
  }

  .about_banner_container {
    position: unset;
    margin: 0 15px;
  }

  .about_banner_container::before {
    display: none;
  }

  .about_banner_container::after {
    display: none;
  }

  .plan_container {
    margin: 10px 15px;
  }

  .testimonials_header {
    margin: 0 15px 20px 15px;
    text-align: center !important;
  }

  .our_partners_header {
    margin: 0 15px;
  }

  .partners_container {
    margin: 0 15px;
  }

  .contact_us_banner {
    margin: 0 15px;
    margin-bottom: 25px;
  }

  .contact_us_header {
    margin: 0 15px;
    text-align: center !important;
    margin-top: 20px;
  }

  .contact_us_form {
    margin: 0 15px;
  }

  .main_footer {
    padding: 0 15px;
  }

  .bottom_bar {
    padding: 0 15px;
    text-align: center;
  }

  .online_test_header {
    margin: 0 15px;
  }

  .online_test_cat {
    margin: 0 15px 20px;
  }

  .about_us .about_us_header {
    text-align: center;
    margin-bottom: 25px;
  }

  .enroll_learners {
    width: 275px;
    padding: 18px;
    height: 95px;
  }

  .enroll_learners img {
    height: 50px !important;
    width: 50px !important;
  }

  .online_support {
    width: 275px;
    padding: 18px;
  }

  .social_icons_container {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }

  .hamburger_icon {
    display: block;
  }

  .top_bar {
    padding: 18px 0;
    background: #fff;
  }

  .top_bar_container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }

  .login_and_signUp {
    display: block;
    text-align: left;
  }

  .login_and_signUp .btn {
    display: block;
  }

  .navbar-brand img {
    max-width: 200px;
    width: 100%;
    height: auto;
  }

  .contact_us_header {
    margin: 0 15px;
    text-align: center !important;
    margin-top: 20px;
  }
}

@media (max-width: 991.98px) {
  /* BOOTSTRAP NAVBAR CSS BEGINS */

  .top_bar {
    padding: 15px 0;
  }

  .top_bar_inner {
    padding: 0 10px;
  }

  .menu_list {
    display: block !important;
    width: 100%;
  }

  .navbar {
    background: rgba(239, 242, 247, 1) !important;
    padding: 0 10px !important;
  }

  .navbar-collapse {
    height: 100vh !important;
  }

  .navbar-nav {
    display: block !important;
    width: 100%;
  }

  .navbar_menu {
    display: block;
    width: 100%;
    text-align: center;
    background: #fff;
    margin: 10px 0;
    padding: 5px 0;
  }

  .top_bar_inner ul {
    border-right: 0;
  }

  .navbar_menu li {
    display: block;
    width: 100%;
  }

  .login_and_signUp .btn {
    display: block;
    width: 100%;
    margin: 15px 0;
    padding: 10px 0;
  }

  /* BOOTSTRAP NAVBAR CSS END */

  .header {
    height: 100vh;
    position: unset;
    background-position: center;
  }

  .header_content {
    position: unset;
    transform: unset;
    display: block;
    width: 100%;
  }

  .our_features {
    background: unset;
    padding: 0 !important;
  }

  .features_container {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .why_us {
    position: unset;
    height: unset !important;
  }

  .why_us_inner {
    position: unset !important;
    padding: 0 15px;
    display: grid;
    gap: 20px;
  }

  .why_us_banner {
    position: unset !important;
    margin: 0 0 25px;
  }

  .why_us .why_us_content {
    position: unset !important;
    max-width: 100% !important;
    width: 100%;
    padding: 50px 32px;
  }

  .about_us {
    padding-top: 0;
    padding-bottom: 70px;
    overflow: hidden;
  }

  .about_banner_container {
    position: unset;
    margin: 0 15px;
  }

  .about_banner_container::before {
    display: none;
  }

  .about_banner_container::after {
    display: none;
  }

  .plan_container {
    margin: 10px 0;
  }

  .testimonials_header {
    margin: 0 15px 20px 15px;
    text-align: center !important;
  }

  .our_partners_header {
    margin: 0 15px;
  }

  .partners_container {
    margin: 0 15px;
  }

  .contact_us_banner {
    margin: 0 15px;
    margin-bottom: 25px;
  }

  .contact_us_header {
    margin: 0 15px;
    text-align: center !important;
  }

  .contact_us_form {
    margin: 0 15px;
  }

  .main_footer {
    padding: 0 15px;
  }

  .bottom_bar {
    padding: 0 15px;
    text-align: center;
  }

  .online_test_header {
    margin: 0 15px;
  }

  .about_us .about_us_header {
    text-align: center;
    margin-bottom: 25px;
  }

  .enroll_learners {
    width: 275px;
    padding: 18px;
    height: 95px;
  }

  .enroll_learners img {
    height: 50px !important;
    width: 50px !important;
  }

  .online_support {
    width: 275px;
    padding: 18px;
    margin: 0 auto 20px;
  }

  .social_icons_container {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }

  .why_us_banner img {
    max-width: 991px;
  }

  .header {
    position: unset;
  }

  .header_content {
    position: unset;
    width: 80%;
  }

  .img_header_left {
    display: none;
  }

  .img_header_right {
    display: none;
  }

  .online_support {
    position: unset;
  }

  .contact_us .contact_us_banner img {
    max-width: 1200px;
  }

  .swiper-wrapper {
    margin-bottom: 80px;
  }

  .swiper-slide {
    background: unset !important;
  }

  .login_and_signUp {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1199.98px) {
  .swiper-wrapper {
    margin-bottom: 30px;
  }
  .footer_inner,
  .contact_us_inner,
  .testimonials_inner,
  .plan_list,
  .test_list_container {
    padding: 0 20px;
  }

  .why_us::before {
    display: none;
  }

  .why_us_banner img {
    max-width: 100% !important;
    width: 100% !important;
    height: auto;
  }

  .why_us .why_us_content {
    max-width: 550px;
    width: 100%;
    padding: 40;
  }

  .about_banner_container .enroll_learners {
    left: 0px;
    top: 95%;
  }
}
