.topLeft_img {
  position: absolute;
  left: -45px;
  top: 0;
  z-index: 1;
}

.carousel_logo {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9999;
}

.carousel_logo a .logo-dark-element {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.banner_content_outer {
  position: absolute;
  z-index: 999;
  /* height: 100%; */
}

.topRight_img {
  position: absolute;
  right: -55px;
  top: -50px;
  z-index: 1;
}

.bottomLeft_img {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  max-width: 200px;
  width: 100%;
}

.bottomRight_img {
  position: absolute;
  right: 20px;
  bottom: 11px;
  z-index: 1;
  /* max-width: 200px;
    width: 100%; */
}

.bottomLeft_img img,
.bottomLeft_img img,
.topRight_img img {
  width: 100%;
  height: auto;
}

@media (max-width: 767.98px) {

  .auth-full-page-content {
    height: unset !important;
    overflow: unset !important;
  }

  .auth-full-page-content {
    height: unset !important;
  }

  .banner_content_outer {
    position: unset !important;
  }

  .bottomRight_img,
  .bottomLeft_img,
  .topRight_img,
  .topLeft_img {
    display: none;
  }

  .banner_content h1,
  .banner_content .h1 {
    font-size: 35px !important;
    line-height: 55px;
    margin-right: 10px;
  }

  .banner_content p {
    font-size: 16px !important;
    margin-right: 10px;
  }

  .banner_content ul li div p {
    font-size: 16px !important;
  }

  .banner_content ul li div img {
    height: 15px;
    width: 15px;
  }

  .auth-full-page-content {
    overflow: unset !important;
  }

  .auth-logo img {
    max-width: 210px !important;
  }

  .auth_content_container .page_title {
    font-size: 32px !important;
  }
}


@media (min-width: 768px) and (max-width: 991.98px) {
  .auth-full-page-content {
    height: unset !important;
    overflow: unset !important;
  }

  .banner_content_outer {
    /* position: unset !important; */
    position: absolute !important;
    z-index: 999 !important;
    width: 70% !important;
    left: 50%;
    transform: translateX(-50%);
  }

  .banner_content {
    width: 70%;
    margin: auto;
  }

  .banner_content h1,
  .banner_content .h1 {
    font-size: 37px !important;
    line-height: 55px;
    margin-right: 10px;
  }

  .banner_content p {
    font-size: 17px !important;
    margin-right: 10px;
  }

  .banner_content ul li div p {
    font-size: 17px !important;
  }

  .banner_content ul li div img {
    height: 17px;
    width: 17px;
  }

  

  .auth-logo img {
    max-width: 220px !important;
  }

  .auth_content_container .page_title {
    font-size: 34px !important;
  }

  .topLeft_img img {
    max-width: 350px !important;
  }
}

@media (min-width: 991.99px) and (max-width: 1199.98px) {
  .auth-full-page-content {
    overflow: auto !important;
    height: 100vh !important;
  }

  .banner_content h1,
  .banner_content .h1 {
    font-size: 32px !important;
    line-height: 55px;
  }

  .banner_content p {
    font-size: 14px !important;
  }

  .banner_content ul li div p {
    font-size: 14px !important;
  }

  .banner_content ul li div img {
    height: 14px;
    width: 14px;
  }

  .auth-logo img {
    max-width: 220px !important;
  }

  .auth_content_container .page_title {
    font-size: 25px !important;
  }

  .topLeft_img img {
    max-width: 350px !important;
  }

  .banner_content_outer {
    position: absolute;
    z-index: 999;
    /* height: 100%; */
  }

  .topRight_img {
    position: absolute;
    right: -55px;
    top: -50px;
    z-index: 1;
  }

  .bottomLeft_img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    max-width: 200px;
    width: 100%;
  }

  .bottomRight_img {
    position: absolute;
    right: 20px;
    bottom: 11px;
    z-index: 1;
  }

}


@media (min-width: 1199.99px) and (max-width: 1399.98px) {

  .auth-full-page-content {
    overflow: auto !important;
    height: 100vh !important;
  }

  .banner_content h1,
  .banner_content .h1 {
    font-size: 40px !important;
    line-height: 55px;
  }

  .banner_content p {
    font-size: 16px !important;
  }

  .banner_content ul li div p {
    font-size: 16px !important;
  }

  .banner_content ul li div img {
    height: 18px;
    width: 18px;
  }

  /* .auth-full-page-content {
    overflow: unset !important;
  } */

  .auth-logo img {
    max-width: 230px !important;
  }

  .auth_content_container .page_title {
    font-size: 30px !important;
  }

  .topLeft_img img {
    max-width: 350px !important;
  }

  .banner_content_outer {
    position: absolute;
    z-index: 999;
    /* height: 100%; */
  }

  .topRight_img {
    position: absolute;
    right: -55px;
    top: -50px;
    z-index: 1;
  }

  .bottomLeft_img {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    max-width: 200px;
    width: 100%;
  }

  .bottomRight_img {
    position: absolute;
    right: 20px;
    bottom: 11px;
    z-index: 1;
    /* max-width: 200px;
      width: 100%; */
  }
}

@media (min-width: 1400px) {
  .auth-full-page-content {
    overflow: auto !important;
    height: 100vh !important;
  }
}