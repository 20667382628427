.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  position: fixed;
  width: 350px;
  height: fit-content;
  background-color: #fff;
  border: 1px solid #243466;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  z-index: 2000;
  white-space: normal;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.tooltip-content.open {
  opacity: 1;
  transform: translateY(0);
}

.tooltip-content p {
  margin: 0;
  color: #333;
}

.tooltip-content .time {
  display: block;
  text-align: right;
  color: #999;
  font-size: 12px;
  margin-top: 5px;
}

.tooltip-arrow {
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #243466;
}

.tooltip-content::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #243466;
}
