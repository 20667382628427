//
// avatar.scss
//
.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8fb;
  border: 1px solid #f6f6f6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.img-thumbnail-border-none {
  padding: 0.25rem;
  background-color: none;
  border: 1px solid none;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.frame-thumbnail {
  padding: 0px;
  background-color: #f8f8fb;
  // border: 1px solid #f6f6f6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.job-frame-xs {
  height: 3.5rem;
  width: 3.5rem;
}
.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.job-frame-sm {
  height: 4.5rem;
  width: 4.5rem;
}
.avatar-sm {
  height: 2.5rem;
  width: 2.5rem !important;
}

.job-frame-md {
  height: 5.0rem;
  width: 5.0rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

// avatar group
.avatar-group {
  padding-left: 12px;
  display: flex;
  flex-wrap: wrap;
  .avatar-group-item {
    margin-left: -12px;
    border: 2px solid var(--#{$prefix}custom-white);
    border-radius: 50%;
    transition: all 0.2s;
    &:hover{
      position: relative;
      transform: translateY(-2px);
    }
  }
}

.tr_img{
    width: 59px !important;
    height: 47px;
    border-radius: 5px;
    object-fit: cover;
}

.tr_img_user{
    width: 59px !important;
    height: 59px !important;
    border-radius: 59px;
    object-fit: cover;
}