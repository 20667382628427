.statistics_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  .title_for_total {
    font-size: 14px;
    font-weight: 400;
    color: #74788d;
    margin: 7px 0 20px 0;
  }

  .btn_view_all {
    font-size: 13px;
    font-weight: 500;
    color: #252f6a !important;
    text-decoration: underline !important;
    white-space: nowrap;
  }

  .stat_img {
    margin-bottom: 20px;
  }
}

.plan_information {
  .plan_info {
    width: 100%;
    padding: 10px 10px 0 0;
    border-radius: 10px;
    overflow: hidden !important;
    .lbl_activeplan {
      background-color: #1ab69d;
      color: #fff;
      padding: 3px 10px;
      margin-bottom: 0;
      font-size: 12px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .lbl_defaultplan {
      background-color: #f89752;
      color: #fff;
      padding: 3px 10px;
      margin-bottom: 0;
      font-size: 12px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .plan_price {
      color: #243466;
      font-size: 20px;
      margin-bottom: 0;

      .plan_dur {
        font-size: 12px;
        margin-bottom: 0;
        color: #74788d;
      }
    }

    .plan_type {
      font-size: 15px;
      color: #243466;
    }
    .plan_slug {
      font-size: 12px;
      color: #243466;
      margin-top: -5px;
    }

    .lbl_plan_remains {
      font-size: 12px;
      color: #000;
    }

    .lbl_remains {
      color: #74788d;
    }
  }

  .active_plan {
    background: #e9f6f4;
  }

  .upgrade_plan {
    background: #f6efe9;
  }

  .plan_desc {
    color: #243466;
    font-size: 13px;
    text-align: left !important;
    margin: 15px 0;
    font-weight: 500;
  }
}

.btn_upgrade_plan {
  font-size: 13px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: #f89752;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  width: 130px;
  white-space: nowrap;
}
.btn_bgprime {
  font-size: 13px;
  font-weight: 500;
  background-color: #252f6a !important;
  color: #fff;
  white-space: nowrap;
  border: "none";
  outline: "none";
  border-radius: 10px !important;
}

.plan_expiry {
  font-size: 13px;
  font-weight: 500;
  color: red;
  white-space: nowrap;
}

.plan_curve {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.section_heading {
  font-size: 20px;
  font-weight: 600;
  color: #495057;
}

.section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section_test_course {
  // .section_header{
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  // }

  .tbl_student_dashboard {
    tr {
      border-bottom: 1px solid #eff2f7;
    }

    th {
      // font-size: 13px;
      font-size: 12px;
      font-weight: 500;
      color: #495057;
      padding: 10px 0;
    }

    td {
      padding: 12px 0;
    }
  }

  .rw_title {
    // font-weight: 500;
    // font-size: 16px;
    // font-family: 'Poppins';
    // color: #252F6A;
    // margin-bottom: 5px;
    font-size: 14px;
    font-family: "Poppins";
    color: #252f6a;
    margin-bottom: 5px;
  }

  .rw_description {
    // font-weight: 400;
    // font-size: 12px;
    // color: #74788D;
    // line-height: 18px;
    // margin-bottom: 0;
    font-weight: 400;
    font-size: 11px;
    color: #74788d;
    line-height: 18px;
    margin-bottom: 0;
  }

  .rw_name_info {
    display: flex;
    align-items: center;
  }

  .rw_img_outer {
    // height: 47px;
    // width: 72px;
    height: 40px;
    width: 60px;
    border-radius: 5px;
    overflow: hidden;
    // margin-right: 38px;
    margin-right: 25px;
  }

  .rw_img_outer img {
    object-fit: cover;
    object-position: center;
  }

  .rw_cat_info {
    .cat_name {
      color: #495057;
      font-weight: 400;
      // font-size: 14px;
      font-size: 12px;
    }
  }

  .rw_dur_info {
    .dur_val {
      font-family: "Inter";
      color: #685f78;
      font-weight: 400;
      // font-size: 14px;
      font-size: 12px;
    }
  }
}

.job_full_info {
  .job_info_inner {
    display: flex;
    justify-content: space-between;

    .job_short_info {
      display: flex;
    }

    .img_job_outer {
      width: 72px;
      height: 72px;
      overflow: hidden;
      border-radius: 5px;
      margin-right: 24px;
    }

    .img_job_outer img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .job_title {
      color: #252f6a;
      // font-size: 20px;
      font-size: 17px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 0;
    }

    .job_company_name {
      color: #252f6a;
      // font-size: 14px;
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;
    }

    .job_ad_time {
      color: #6a7187;
      // font-size: 14px;
      font-size: 12px;
      font-weight: 500;
    }

    .btn_apply_now {
      background: #252f6a;
      padding: 9px 20px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
    }
  }

  .job_description {
    color: #323133;
    // font-size: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    margin: 10px 0 20px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .jobs_additional_info {
    display: flex;
    gap: 30px;

    .additional_info {
      display: flex;
      align-items: center;
      gap: 10px;

      .additional_info_name {
        color: #3d3d3d;
        // font-size: 14px;
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
      }
    }
  }
}
